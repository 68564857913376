/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
//@import "~material-design-icons/iconfont/material-icons.css";
//@import "~highlight.js/styles/googlecode.css";
$material-design-icons-font-path: "material-design-icons-iconfont/dist/fonts/";
@import "material-design-icons-iconfont/dist/material-design-icons";
// header base skins
.kt-header-base-light {
	@import "sass/global/layout/header/skins/base/light.scss";
}
.kt-header-base-dark {
	@import "sass/global/layout/header/skins/base/dark.scss";
}

// header menu skins
.kt-header-menu-light {
	@import "sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
	@import "sass/global/layout/header/skins/menu/dark.scss";
}

// brand skins
.kt-brand-dark {
	@import "sass/global/layout/brand/skins/dark.scss";
}
.kt-brand-light {
	@import "sass/global/layout/brand/skins/light.scss";
}

// aside skins
.kt-aside-dark {
	@import "sass/global/layout/aside/skins/dark.scss";
}
.kt-aside-light {
	@import "sass/global/layout/aside/skins/light.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px !important;
	width: 100% !important;
}

.position-static {
	position: static;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap';

a {
  text-decoration: none!important;
}

.btn-secondary {
	color: #5e79b6 !important

}

